import { useMessageStore } from '../message'
import type {
  CertificateType,
  CourseDetailType,
  PayloadAnswer,
  PayloadCourseContent,
  PayloadCourseContentUpsertProgress,
  PayloadCourseContentVideo,
  PayloadCourseDetail,
  PayloadCourseDetailRates,
  PayloadCourseList,
  PayloadCourseRate,
  PayloadSearchCourse,
  PayloadSearchCourseResult,
  PayloadUploadFile,
  PayloadVoucher,
  PayloadWishlist,
  StateCoursesType
} from './types'
import { type ActionParams } from 'types/storeTypes'
import type { BaseResponse } from '~~/types/contracts/base'

const useActions = (state: StateCoursesType) => {
  const { handleErrorInfo } = useCustomFetch()
  const messageStore = useMessageStore()
  const { token } = useAuthStorage()

  const { API_URL } = useBaseUrl()

  const fetchCourseDetail = async ({
    payload,
    resolve = () => null,
    reject = (T) => null
  }) => {
    state.courseDetail.isLoading = true
    try {
      const params = payload?.params ?? null
      const res: any = await $fetch(
        `${API_URL}/course/${payload?.slugCourse ?? payload}?${params}`,
        {
          method: 'get',
          headers: {
            Authorization: token.value
          }
        }
      )
      state.courseDetail = {
        data: res?.data,
        isLoading: false
      }
      resolve()
    } catch (error: any) {
      reject(error?.response?.status)
      messageStore.notifyStdError(
        error?.data?.message ?? error?.response?.status
      )
      throw createError({
        statusCode: error?.response?.status,
        statusMessage: `fetchCourseDetail: ${error?.data ?? error}`
      })
    } finally {
      state.courseDetail.isLoading = false
    }
  }

  const fetchCourseDetailReview = async ({ payload, resolve = () => null }) => {
    state.courseReview.isLoading = true
    try {
      const res: any = await $fetch(
        `${API_URL}/courses/${payload.courseSerial}/rates?${payload?.query}`
      )
      state.courseReview = {
        data: res?.data,
        isLoading: false
      }
      resolve()
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
      throw createError({
        statusCode: error?.status,
        statusMessage: `fetchCourseDetailReview: ${error?.message ?? error}`
      })
    } finally {
      state.courseReview.isLoading = false
    }
  }

  const fetchCourseContent = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadCourseContent>) => {
    state.courseContent.isLoading = true
    const { data, error } = await useFetch(
      `${API_URL}/courses/${payload?.courseSerial}/sections/${payload?.sectionSerial}/contents/${payload?.contentSerial}?${payload?.params}`,
      {
        method: 'get',
        headers: {
          Authorization: token.value
        }
      }
    )

    if (!data.value && error.value) {
      state.courseContent.isLoading = false
      handleErrorInfo(error, 'fetchCourseContent')
    } else {
      const courseData = useResult(data.value, 'data', null)
      if (courseData?.type === 'QUIZ') {
        courseData?.questions?.forEach((question, index) => {
          question.rank = index + 1
        })
      }

      state.courseContent = {
        data: courseData,
        isLoading: false
      }
      resolve()
    }
  }

  const fetchCourseContentVideo = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadCourseContentVideo>) => {
    const { data, error } = await useFetch(
      `${API_URL}/courses/content-video?${payload}`,
      {
        method: 'get',
        headers: {
          Authorization: token.value
        }
      }
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'fetchCourseContentVideo')
    } else {
      state.courseContentVideo = useResult(data.value, 'data', null)
      resolve()
    }
  }

  const postEnrollCourse = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadCourseContent>) => {
    const { error } = await useAsyncData('enroll', () =>
      $fetch(`${API_URL}/courses/users/enroll`, {
        method: 'POST',
        headers: {
          Authorization: token.value
        },
        body: payload
      })
    )

    if (error.value) {
      handleErrorInfo(error, 'postEnrollCourse')
    }

    resolve()
  }

  const upsertCourseContentProgress = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadCourseContentUpsertProgress>) => {
    const { data, error } = await useFetch(
      `${API_URL}/courses/users/contents/progress`,
      {
        method: 'post',
        headers: {
          Authorization: token.value
        },
        body: payload
      }
    )

    if (error.value) {
      handleErrorInfo(error, 'upsertCourseContentProgress')
    }

    const result = useResult(data.value, 'data', null)

    resolve(result)
  }

  const accomplishingCourse = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadCourseContent>) => {
    const { error } = await useFetch(
      `${API_URL}/courses/users/accomplishment`,
      {
        method: 'post',
        headers: {
          Authorization: token.value
        },
        body: payload
      }
    )

    if (error.value) {
      handleErrorInfo(error, 'accomplishingCourse')
    }

    resolve()
  }

  const generateCourseCertificate = async ({
    payload,
    resolve = () => null,
    preload = () => null
  }: ActionParams<PayloadCourseContent>) => {
    const { data, error, pending } = await useAsyncData('certificate', () =>
      $fetch(`${API_URL}/courses/users/certificate`, {
        method: 'POST',
        headers: {
          Authorization: token?.value ?? ''
        },
        body: payload
      })
    )

    preload(pending)

    if (error.value) {
      handleErrorInfo(error, 'generateCourseCertificate')
    }

    const result: CertificateType = useResult(data.value, 'data', null)

    resolve(result)
  }

  const courseRating = async ({
    payload,
    resolve = () => null,
    preload = () => null
  }: ActionParams<PayloadCourseRate>) => {
    const { data, error, pending } = await useAsyncData('courseRating', () =>
      $fetch(`${API_URL}/courses/users/rate`, {
        method: 'POST',
        headers: {
          Authorization: token?.value ?? ''
        },
        body: payload
      })
    )

    preload(pending)

    if (error.value) {
      handleErrorInfo(error, 'courseRating')
    }

    resolve(useResult(data.value, 'data', null))
  }

  const facilitatorsRating = async ({
    payload,
    resolve = () => null,
    reject = () => null
  }: ActionParams<PayloadCourseRate>) => {
    const { data, error } = await useAsyncData('facilitatorRating', () =>
      $fetch(`${API_URL}/courses/users/rate-facilitators`, {
        method: 'POST',
        headers: {
          Authorization: token?.value ?? ''
        },
        body: payload
      })
    )

    if (error.value) {
      reject()
      handleErrorInfo(error, 'facilitatorsRating')
    }

    resolve(useResult(data.value, 'data', null))
  }

  const fetchWishlist = async () => {
    const { data, error, pending } = await useAsyncData('getWishlist', () =>
      $fetch(`${API_URL}/courses/users/wishlist`, {
        method: 'get',
        headers: {
          Authorization: token.value
        }
      })
    )

    state.wishlist.isLoading = pending?.value ?? false
    if (!data.value && error.value) {
      handleErrorInfo(error, 'fetchWishlist')
    }
    state.wishlist = {
      data: useResult(data.value, 'data', null),
      isLoading: false
    }
  }

  const sendWishlist = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadWishlist>) => {
    const { error } = await useAsyncData('sendWishlist', () =>
      $fetch(`${API_URL}/courses/users/wishlist`, {
        method: 'post',
        headers: {
          Authorization: token.value
        },
        body: {
          courseSerial: payload.courseSerial
        }
      })
    )

    if (error.value) {
      handleErrorInfo(error, 'sendWishlist')
    }

    if (payload.wishlist) {
      messageStore.triggerMessage({
        type: 'success',
        body: 'Berhasil menambahkan ke wishlist!'
      })
    }

    resolve()
  }

  const validateAnswer = async ({
    payload,
    resolve = () => null
  }: ActionParams<PayloadAnswer>) => {
    const { data, error } = await useAsyncData('enroll', () =>
      $fetch(`${API_URL}/questions/${payload?.serial}/validations`, {
        method: 'POST',
        headers: {
          Authorization: token.value
        },
        body: {
          answer: payload?.answer
        }
      })
    )

    if (error.value) {
      handleErrorInfo(error, 'validateAnswer')
    }

    const answer = useResult(data.value, 'data', null)?.isCorrect
    resolve(answer)
  }

  const uploadFile = async ({
    payload,
    resolve = () => null,
    preload = () => null
  }: ActionParams<PayloadUploadFile>) => {
    preload(true)
    const { data, error } = await useAsyncData('enroll', () =>
      $fetch(`${API_URL}/file-upload/file`, {
        method: 'POST',
        headers: {
          Authorization: token.value
        },
        body: payload
      })
    )

    if (error.value) {
      preload(false)
      handleErrorInfo(error, 'uploadFile')
    }

    const result = useResult(data.value, 'data', null)
    resolve(result)
    preload(false)
  }

  const claimVoucher = async ({
    payload,
    resolve = () => null,
    reject = () => null
  }: ActionParams<PayloadVoucher>) => {
    const { error } = await useAsyncData('claim', () =>
      $fetch(`${API_URL}/vouchers/claims`, {
        method: 'POST',
        headers: {
          Authorization: token.value
        },
        body: payload
      })
    )

    if (error.value) {
      reject(error.value)
      return
    }

    resolve()
  }

  const searchCourse = async ({
    payload,
    resolve = () => null,
    reject = () => null
  }: ActionParams<PayloadSearchCourse>) => {
    const { data, error } = await useAsyncData('searchCourse', () =>
      $fetch(`${API_URL}/courses/search`, {
        method: 'GET',
        headers: {
          Authorization: token?.value ?? ''
        },
        params: payload
      })
    )

    if (error.value) {
      reject(error.value)
      return
    }

    const result = useResult(data.value, 'data', null)
    resolve(result)
  }

  const searchCourseResult = async ({
    payload
  }: ActionParams<PayloadSearchCourseResult>) => {
    const { data, error, pending } = await useAsyncData(
      'searchCourseResult',
      () =>
        $fetch(`${API_URL}/courses/search-result`, {
          method: 'GET',
          headers: {
            Authorization: token?.value ?? ''
          },
          params: payload?.params
        })
    )

    state.searchResult.isLoading = pending.value

    if (error.value) {
      const statusCode: number = useResult(error.value, 'message', 0)
      messageStore.notifyStdError(statusCode)
      state.searchResult.isLoading = false
      return
    }

    state.searchResult.isLoading = pending.value
    const searchData = useResult(data.value, 'data', null)
    if (!payload?.isMore) {
      state.searchResult = {
        data: searchData,
        meta: useResult(data.value, 'meta', null),
        isLoading: false
      }
    } else {
      state.searchResult.meta = useResult(data.value, 'meta', null)
      searchData?.courses?.forEach((course) => {
        state.searchResult.data.courses?.push(course)
      })
    }
  }

  const fetchCourseList = async ({
    payload
  }: ActionParams<PayloadCourseList>) => {
    state.courseList.isLoading = true
    try {
      const res: any = await $fetch(`${API_URL}/courses`, {
        headers: {
          Authorization: token?.value ?? ''
        },
        params: payload
      })
      state.courseList = {
        data: res?.data,
        meta: res?.meta,
        isLoading: false
      }
    } catch (error: any) {
      messageStore.notifyStdError(error?.status)
      throw createError({
        statusCode: error?.status,
        statusMessage: `fetchBanners: ${error?.message ?? error}`
      })
    } finally {
      state.courseList.isLoading = false
    }
  }

  return {
    fetchCourseDetail,
    fetchCourseDetailReview,
    fetchCourseContent,
    fetchCourseContentVideo,
    postEnrollCourse,
    upsertCourseContentProgress,
    accomplishingCourse,
    generateCourseCertificate,
    courseRating,
    facilitatorsRating,
    fetchWishlist,
    sendWishlist,
    validateAnswer,
    uploadFile,
    claimVoucher,
    searchCourse,
    searchCourseResult,
    fetchCourseList
  }
}

export default useActions
